const distinct = (value, index, self) => {
	return self.indexOf(value) === index
}

function showSelected() {
	if (localStorage.getItem('samples') != null) {
		let currentSamples = JSON.parse(localStorage.samples)
		let selectedForm = document.querySelector('#acf-field_62a73f3062b7f')
		let selectedSamples = []
		let sampleOptions = document.querySelectorAll("[data-sample-id]")

		sampleOptions.forEach(function (el, i) {
			if (currentSamples.includes(el.dataset.sampleId)) {
				el.classList.add('selected')
				el.setAttribute('disabled', 'disabled')
				selectedSamples.push(el.dataset.sampleName)
			} else {
				el.classList.remove('selected')
			}
		})
		let selectDis = selectedSamples.filter(distinct)
		let selectedStr = selectDis.join(", ")
		if (selectedForm) {
			selectedForm.value = selectedStr
		}
		updateSampleCount(currentSamples.length)
	} else {
		const orderBtn = document.querySelector('.order')
		if (orderBtn) {
			orderBtn.classList.add('disabled')
		}
	}
}

{
	setupSampleCount()
	showSelected()
}

function updateSelected(el) {
	let sampleID = el.dataset.sampleId
	let sampleName = el.dataset.sampleName
	if (localStorage.getItem('samples') === null) {
		let samples = []
		samples.push(sampleID)
		showAlert(sampleName, 'added')
		localStorage.setItem('samples', JSON.stringify(samples))
	} else {
		let currentSamples = JSON.parse(localStorage.samples)
		if (currentSamples.includes(sampleID)) {
			updatedSamples = currentSamples.filter(e => e !== sampleID)
			showAlert(sampleName, 'removed')
			localStorage.setItem('samples', JSON.stringify(updatedSamples))
		} else {
			if (currentSamples.length < 3) {
				currentSamples.push(sampleID)
				el.setAttribute('disabled', 'disabled')
				showAlert(sampleName, 'added')
				localStorage.setItem('samples', JSON.stringify(currentSamples))
			} else {
				toggleAlert()
			}
		}
	}
	showSelected()
}

function setupSampleCount() {
	const navItems = document.querySelectorAll('.navigation--sidebar__menu li a')
	navItems.forEach((el, id) => {
		if (el.innerHTML == 'Free Samples') {
			el.innerHTML += '<span class="sample_count hide"><span></span></span>';
			el.parentElement.classList.add('samples_nav')
		}
	})
}

function updateSampleCount(count = 0) {
	const navItemSpan = document.querySelector('span.sample_count')
	const navItemSpanCount = document.querySelector('span.sample_count span')
	const finishOrderBtn = document.querySelector('.order')

	if (count > 0) {
		navItemSpanCount.innerHTML = count;
		navItemSpan.classList.remove('hide')
		if (finishOrderBtn) { finishOrderBtn.classList.remove('disabled') }
	} else {
		navItemSpan.classList.add('hide')
		navItemSpanCount.innerHTML = '';
		if (finishOrderBtn) { finishOrderBtn.classList.add('disabled') }
	}
}

function showAlert(sampleName, direction) {
	const alertBox = document.querySelector('.saige-sample-add')
	let text = alertBox.querySelector('p')
	let sample = (direction == 'added') ? ' has been added to your order' : ' has been removed from your order'
	text.innerHTML = sampleName + sample
	alertBox.classList.remove('added', 'removed')
	alertBox.classList.add('show', direction)
	setTimeout(() => {
		alertBox.classList.remove('show', direction)
	}, 3000);
}

function toggleAlert() {
	const alertBox = document.getElementById('error')
	alertBox.classList.toggle('visible')
}

// Make popup form show on Samples page
function finishOrder() {
	const orderForm = document.getElementById('orderSamples')
	if (!document.querySelector('.order').classList.contains('disabled')) {
		orderForm.classList.add('visible')
		showSelected()
	}
}


let autocomplete, searchField
let address1Field, address2Field, address3Field, townField, countyField, postcodeField, countryField

function initAutocomplete() {
	searchField = document.getElementById("acf-field_62a73cff4d843")
	let options = {
		componentRestrictions: { country: ["uk", "ie"] },
		fields: ["address_components"],
		// types: ["address"]
	}
	autocomplete = new google.maps.places.Autocomplete(searchField, options)
	autocomplete.addListener("place_changed", fillInAddress);

	setTimeout(function () {
		searchField.setAttribute("autocomplete", 'chrome-off')
	}, 500)

	address1Field = document.getElementById('acf-field_62a86d026e0ea-field_62a73da51ab3f');
	address2Field = document.getElementById('acf-field_62a86d026e0ea-field_62a73dab1ab40');
	address3Field = document.getElementById('acf-field_62a86d026e0ea-field_62a73dae1ab41');
	townField = document.getElementById('acf-field_62a86d026e0ea-field_62a73db11ab42');
	countyField = document.getElementById('acf-field_62a86d026e0ea-field_62a73db61ab43');
	postcodeField = document.getElementById('acf-field_62a86d026e0ea-field_62a7465803f90');
	countryField = document.getElementById('acf-field_62a86d026e0ea-field_62a73dbc1ab44');

	address1Field.setAttribute("autocomplete", 'chrome-off')
	address2Field.setAttribute("autocomplete", 'chrome-off')
	address3Field.setAttribute("autocomplete", 'chrome-off')
	townField.setAttribute("autocomplete", 'chrome-off')
	countyField.setAttribute("autocomplete", 'chrome-off')
	postcodeField.setAttribute("autocomplete", 'chrome-off')
	countryField.setAttribute("autocomplete", 'chrome-off')

}

function fillInAddress() {
	const place = autocomplete.getPlace();

	address1Field.value = ''
	address2Field.value = ''
	address3Field.value = ''
	townField.value = ''
	countyField.value = ''
	postcodeField.value = ''
	countryField.value = ''

	let address1 = '',
		county = '',
		postcode = ''

	for (const component of place.address_components) {
		const componentType = component.types[0];
		switch (componentType) {
			case "street_number":
			case "premise": {
				address1 = `${component.long_name}`;
				break;
			}

			case "route": {
				address1 += ` ${component.long_name}`;
				break;
			}

			case "postal_code": {
				postcode = `${component.long_name}`;
				break;
			}

			case "postal_code_suffix":
			case "postal_code_prefix": {
				postcode += component.long_name;
				break;
			}

			case "locality":
				address2Field.value = component.long_name;
				break;

			case "neighbourhood":
			case "postal_town":
				townField.value = component.long_name;
				break;

			case "administrative_area_level_1":
				if (county == '') county = component.long_name
				break;

			case "administrative_area_level_2":
				county = component.long_name;
				break;

			case "country":
				countryField.value = component.long_name;
				break;
		}


	}

	countyField.value = county;
	address1Field.value = address1;
	postcodeField.value = postcode;

	openManualAddress(document.querySelector('.acf-field-62a86d026e0ea'), true)
}

function addressToggle() {
	const addressToggle = document.querySelector('.acf-field-62a86db6c02c2')
	const addressFields = document.querySelector('.acf-field-62a86d026e0ea')
	addressFields.classList.add('js-active')

	addressToggle.addEventListener('click', function () {
		addressToggle.classList.toggle('active')
		openManualAddress(addressFields)
	}, { passive: false })
}

function openManualAddress(addressFields, force = false) {
	if (force) { // Force address to stay open, used when second address is autofilled
		addressFields.style.maxHeight = addressFields.scrollHeight + 'px'
	} else {
		if (addressFields.style.maxHeight) {
			addressFields.style.maxHeight = null
		} else {
			addressFields.style.maxHeight = addressFields.scrollHeight + 'px'
		}
	}

}

window.addEventListener('load', orderFormInit, { passive: false })
function orderFormInit() {
	const orderForm = document.querySelector('.saige-sample-order');
	if (orderForm) {
		initAutocomplete()
		disableFirstSelect()
		closeSubmission()
		addressToggle()
	}
}

window.addEventListener('load', contactFormInit, { passive: false })
function contactFormInit() {
	const contactForm = document.querySelector('#form_630f7464785fc')
	if (contactForm) {
		document.querySelectorAll('#acf-field_6331a1a521fb2 option').forEach(opt => {
			if (opt.value == 'none') {
				opt.setAttribute('hidden', 'hidden')
			}
		})
	}
}

window.addEventListener('load', commercialContactFormInit, { passive: false })
function commercialContactFormInit() {
	const contactForm = document.querySelector('#form_6304b242d18fd')
	if (contactForm) {
		document.querySelectorAll('#acf-field_6331a1d654f98 option').forEach(opt => {
			if (opt.value == 'none') {
				opt.setAttribute('hidden', 'hidden')
			}
		})
	}
}

function disableFirstSelect() {
	document.querySelectorAll('#acf-field_62a73c8c4d841 option').forEach(opt => {
		if (opt.value == 'none') {
			opt.setAttribute('hidden', 'hidden')
		}
	})
	document.querySelectorAll('#acf-field_62a73cdb4d842 option').forEach(opt => {
		if (opt.value == 'none') {
			opt.setAttribute('hidden', 'hidden')
		}
	})
	document.querySelectorAll('#acf-field_6331a406858ce option').forEach(opt => {
		if (opt.value == 'none') {
			opt.setAttribute('hidden', 'hidden')
		}
	})
}

function closeSubmission() {
	const closeBtn = document.querySelector('.close_submission span')
	closeBtn.addEventListener('click', function () {
		const orderForm = document.querySelector('.saige-sample-order')
		orderForm.classList.toggle('visible')
	}, { passive: false })
}