function createGA4Event(name, properties) {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({
		event: name,
		...properties,
	})
}

jQuery(document).ready(() => {
	// If ACF is loaded on page
	if (window.acf && jQuery('#form_62a73c2047c15').length > 0) {
		const sampleNames = {
			1094: 'Balustrades',
			1093: 'Fencing',
			1092: 'Traditional Co-Extruded Decking',
			1091: 'Contemporary Decking',
			1090: 'Rustic Decking',
		}

		acf.add_filter('validation_complete', function (json, $form) {
			if (!json.errors) {
				let samplesCount = JSON.parse(localStorage.samples).length
				let chosenSamples = JSON.parse(localStorage.samples).map((sample) => {
					return sampleNames[sample]
				})

				createGA4Event('samplesFormSubmit', {
					samplesCount: samplesCount,
					samples: chosenSamples,
				})
			}
			return json
		})
	}

	setupScrollListener()

	// Anchor Links
	// let anchorLinks = document.querySelectorAll('[href^="#"]')
	// anchorLinks.forEach((link) => {
	// 	link.addEventListener('click', (e) => {
	// 		e.preventDefault()

	// 		let destIndex = link.href.indexOf("#"),
	// 			dest = link.href.substring(destIndex);

	// 		if (document.querySelector(dest)) {
	// 			locomotive.scrollTo(dest, { offset: -100 })
	// 		}
	// 	})
	// })
})

function setupScrollListener() {
	const el = document.querySelector(".why-saige")
	if (!el) return

	var observer = new IntersectionObserver(function (entries) {
		if (entries[0].isIntersecting === true) {
			el.classList.add('is-active')
			observer.unobserve(el);
		}
	}, { threshold: [1] });

	observer.observe(el);
}

class SkipBtns {
	constructor(container) {
		this.container = container
		if(!this.container) return

		this.btns = this.container.querySelectorAll('.btn')

		this.init()
	}

	bindAll() {
		["btnClickEvent"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	btnClickEvent(e) {
		let el = e.srcElement
		this.btnClick(el)
	}

	btnClick(el) {
		let target = el.dataset.target
		scrollToHash(target)
	}

	init() {
		this.bindAll()
		this.btns.forEach( (el) => {
			el.addEventListener('click', this.btnClickEvent)
		})
	}
}

const skipBtns = document.querySelector('.skip_nav')
const skip = new SkipBtns(skipBtns)