if (jQuery('.question').length > 0) {
	jQuery('.question').on('click', function () {
		jQuery(this).toggleClass('open');
		var answer = jQuery(this).next()[0];

		if (answer.style.maxHeight) {
			answer.style.maxHeight = null;
		} else {
			answer.style.maxHeight = answer.scrollHeight + "px";
		}

		setTimeout(() => {
			// locomotive.update();
		}, 500)
	})

}