jQuery('.slider-content_single--slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	arrows: true,
	autoplay: true,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	pauseOnFocus: false,
	prevArrow: '<button type="button" class="previous"><i class="far fa-chevron-left"></i></button>',
	nextArrow: '<button type="button" class="next"><i class="far fa-chevron-right"></i></button>',
});

jQuery('.location_gallery').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots: false,
	false: true,
	autoplay: true,
	autoplaySpeed: 4000,
	pauseOnHover: false,
	pauseOnFocus: false	
});