// ------------------
// Navigation
// ------------------

let $navigation = document.querySelector(".navigation--main"),
	$navFade = document.querySelector(".navigation--fade"),
	$menu = $navigation.querySelector(".navigation--main__menu"),
	$logo = document.querySelector(".saige-header"),
	$hamburger = document.querySelectorAll(".hamburger-bars"),
	$sidebar = document.querySelector(".navigation--sidebar"),
	_isNavOpen = false,
	navFadeClickEvent = null;

$hamburger.forEach((burger) => {
	burger.addEventListener("click", () => {
		handleNavState();
	});
});

function determineFadeState() {
	if (navFadeClickEvent) {
		$navFade.removeEventListener("click", handleNavState);
	} else {
		$navFade.addEventListener("click", handleNavState);
	}
}

function handleNavState() {
	_isNavOpen = !_isNavOpen;
	determineFadeState();
	$navFade.classList.toggle("is-nav-active");
	$hamburger[0].classList.toggle("is-active");
	$hamburger[1].classList.toggle("is-active");
	$navigation.classList.toggle("is-active");
	$sidebar.classList.toggle("is-active");
	$logo.classList.toggle("is-nav-active");
	document.querySelector("body").classList.toggle("stop-scrolling");
	setTimeout(() => {
		$menu.classList.remove("open");
	}, 500);
}

// ------------------
// Dropdown Opening
// ------------------

var navParentItems = $navigation.querySelectorAll("li.menu-item-has-children"),
	activeDropdown = null,
	dropdownTimeout = null;

navParentItems.forEach((item) => {
	let dropdown = item.querySelector(".dropdown-menu"),
		backButton = item.querySelector(".menu_back_btn");

	item.addEventListener("click", (e) => {
		handleMobileDropdown(e);
	});
	backButton.addEventListener("click", (e) => {
		handleCloseDropdown(e);
	});
});

function handleCloseDropdown() {
	$menu.classList.remove("open");
}

function handleMobileDropdown(event) {
	if (!event.target.classList.contains("menu_back_btn")) {
		$menu.classList.add("open");
	}
}

// function handleHoverEnter(el) {
//     if(el == activeDropdown) {
//         if(dropdownTimeout) {
//             clearTimeout(dropdownTimeout)
//             dropdownTimeout = 0
//         }
//         return
//     }

//     activeDropdown = el;
//     activeDropdown.classList.add('is-active-dropdown');
// }

// function handleHoverOver(el) {
//     if(el == activeDropdown) {
//         if(dropdownTimeout) {
//             clearTimeout(dropdownTimeout)
//             dropdownTimeout = 0
//         }
//     }
//     return
// }

// function handleHoverLeave(el) {
//     if(el != activeDropdown) return

//     dropdownTimeout = setTimeout(() => {
//         activeDropdown.classList.remove('is-active-dropdown')
//         activeDropdown = null
//     }, 500)
// }
