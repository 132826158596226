class StickyProductSlider {
	constructor(el) {
		this.anatomyContainer = document.querySelector(el);

		if (!this.anatomyContainer) return;

		this.anatomyNavList =
			this.anatomyContainer.querySelector(".anatomy--nav");
		this.anatomyGraphicItems = this.anatomyContainer.querySelectorAll(
			".anatomy--graphic_single"
		);
		this.anatomyNavItems = this.anatomyNavList.querySelectorAll(
			".anatomy--nav_single"
		);
		this.anatomyHotspot = this.anatomyContainer.querySelector(
			".anatomy--graphic_hotspot"
		);
		this.anatomyPopup = document.querySelector(".anatomy--popup");
		this.anatomyPopupVimeo = document.querySelector("#anatomyVimeo");
		this.viewableArea =
			this.anatomyContainer.querySelector(".anatomy-inner");

		this.slidesCount = this.anatomyNavItems.length;
		this.navWidth = this.anatomyNavList.getBoundingClientRect().width;
		this.activeIndex = null;
		this.progress = 0;
		this.vimeo = null;
		this.featherlight = null;
		this.popupOpen = false;
		this.anatomyContainerBounding = this.anatomyContainer.getBoundingClientRect();
		this.isAutoScrolling = false;

		this.scrollEvent = null;

		this.bindAll()
		this.calculateAffix()
		this.setupEvents();
		this.heightAnatomy()	

	}

	bindAll() {
		["calculateAffix"].forEach((fn) => (this[fn] = this[fn].bind(this)));
	}

	calculateAffix() {
		// Determine if anatomy is above, below, or on screen
		let scrollPos = document.documentElement.scrollTop
		let anatomyTop = this.anatomyContainer.getBoundingClientRect().top + document.documentElement.scrollTop
		let anatomyHeight = this.anatomyContainerBounding.height

		let innerScrollPos = scrollPos - anatomyTop
		let perc = innerScrollPos / (anatomyHeight - window.innerHeight)

		if (perc > 1) {
			// console.log('BELOW') 
			perc = 1
			this.viewableArea.classList.remove('affix')
			this.viewableArea.classList.remove('affix-top')
			this.viewableArea.classList.add('affix-bottom')
		} else if (perc < 0) {
			// console.log('ABOVE') 
			perc = 0
			this.viewableArea.classList.remove('affix-bottom')
			this.viewableArea.classList.remove('affix')
			this.viewableArea.classList.add('affix-top')
		} else {
			// console.log('ACTIVE') 
			this.viewableArea.classList.remove('affix-bottom')
			this.viewableArea.classList.remove('affix-top')
			this.viewableArea.classList.add('affix')
		}

		this.updateProgress(perc)
	}

	updateProgress(progress) {
		this.progress = progress;

		this.determineSlide();
		this.setProgressVariables();
	}

	setProgressVariables() {
		let overflow = this.navWidth - window.innerWidth,
			toMove = overflow * this.progress,
			percToMove = (toMove / this.navWidth) * 100;

		this.anatomyContainer.style.setProperty(
			"--progress",
			`${this.progress * 100}%`
		);
		this.anatomyContainer.style.setProperty(
			"--progress-mobile",
			`${percToMove}%`
		);
	}

	determineSlide() {
		let newIndex = Math.ceil(
			(this.progress * 100) / (100 / this.slidesCount)
		) - 1;
		if (newIndex < 0) newIndex = 0

		this.setActiveSlide(newIndex);
	}

	setInitialSlide() {
		let currentSlide = this.getActiveItems();
		currentSlide.graphic.classList.add("active");
		currentSlide.nav.classList.add("active");

		this.setHotspot(currentSlide.nav.dataset.hotspotX, currentSlide.nav.dataset.hotspotY)
	}

	setActiveSlide(index, bypass = false) {
		if (
			index == this.activeIndex ||
			(index >= this.slidesCount && index <= 1) ||
			(this.isAutoScrolling && bypass == false)
		) {

			return false;
		}

		let currentSlide = this.getActiveItems(index);
		if (currentSlide.graphic) {
			currentSlide.graphic.classList.remove("active");
			currentSlide.nav.classList.remove("active");
		}

		this.activeIndex = index;

		let nextSlide = this.getActiveItems();
		if (nextSlide.graphic) {
			nextSlide.graphic.classList.add("active");
			nextSlide.nav.classList.add("active");
		}

		this.setHotspot(nextSlide.nav.dataset.hotspotX, nextSlide.nav.dataset.hotspotY)
		this.setDataset(nextSlide.nav.dataset.pvimeoId, nextSlide.nav.dataset.pvimeoTitle)
	}

	setHotspot(x, y) {
		this.anatomyContainer.style.setProperty(
			"--hotspot-x",
			`${x}%`
		);
		this.anatomyContainer.style.setProperty(
			"--hotspot-y",
			`${y}%`
		);
	}

	setDataset(id, title) {
		this.anatomyHotspot.dataset.activeVideoId = id;
		this.anatomyHotspot.dataset.activeVideoTitle = title
	}

	heightAnatomy() {
		this.anatomyNavHeight = this.anatomyNavList.getBoundingClientRect().height
		this.anatomyContainer.style.setProperty('--anatomyNavHeight', this.anatomyNavHeight + 'px')
	}

	setupEvents() {
		let t = this;

		// Resize event listener
		t.setViewableHeight();
		window.addEventListener("resize", () => {
			t.setViewableHeight();
			t.navWidth = t.anatomyNavList.getBoundingClientRect().width;
			t.heightAnatomy()
		});



		// ** Make nav items clickable
		this.anatomyNavItems.forEach((navItem) => {
			navItem.addEventListener("click", () => {
				let id = parseInt(navItem.getAttribute("data-anatomy-id")),
					anatomyHeight =
						t.anatomyContainerBounding.height - window.innerHeight;

				t.isAutoScrolling = true;
				t.setActiveSlide(id, true);

				t.setProgressVariables();
			});
		});

		window.addEventListener('scroll', this.calculateAffix)

		this.anatomyHotspot.addEventListener("click", () => {
			this.handlePopUpOpen();
		});

		let closeBtn = this.anatomyPopup.querySelector(".close-btn");
		closeBtn.addEventListener("click", () => {
			// this.handlePopUpClose()
		});

		// Close Popup on Esc
		document.addEventListener("keydown", function (event) {
			if (event.key === "Escape" && t.popupOpen) {
				t.handlePopUpClose();
			}
		});

		// Close Popup on blank space click
		this.anatomyPopup.addEventListener("pointerup", (e) => {
			t.handlePopUpClose();
		});
	}

	setViewableHeight() {
		this.viewableArea.style.height = window.innerHeight + "px";
		// locomotive.update();
	}

	handlePopUpOpen() {
		if (!this.anatomyPopup) return;
		let t = this;
		// this.popup.removeEventListener('keypress');

		// Open Popup
		// locomotive.stop();
		this.anatomyPopup.classList.add("is-active");

		this.popupOpen = true;
		this.handleVimeo(this);
	}

	handlePopUpClose() {
		if (!this.anatomyPopup) return;

		// locomotive.start();
		this.anatomyPopup.classList.remove("is-active");
		this.vimeo.pause();
		this.popupOpen = false;
	}

	handleVimeo(t) {
		let id = t.getActiveItems().nav.dataset.pvimeoId;

		if (!t.vimeo) {
			// Init vimeo
			t.vimeo = new Vimeo.Player("anatomyVimeo", {
				id: id,
				byline: false,
				portrait: false,
				title: false,
				color: "E62B4D",
			});
		} else {
			// Change video
			t.vimeo.loadVideo(id).catch(function (error) {
				console.log("Error changing video", error);
			});
		}
	}

	getActiveItems() {
		return {
			graphic: this.anatomyGraphicItems[this.activeIndex],
			nav: this.anatomyNavItems[this.activeIndex],
		};
	}
}

// Only run this script if the anatomy is on the page.
// - Should really only be loaded on pages with the anatomy
//	  but that should come in the future

if ($el = document.getElementById('anatomy')) {

	const productSlider = new StickyProductSlider(".anatomy");

	// let parent = document.querySelector('#anatomy')
	// let anatomyAffix = jQuery('.anatomy-inner').affix({
	// 	offset: {
	// 		top: function () {
	// 			return getPositionFromTopOfDoc(parent)
	// 		},
	// 		bottom: function () {
	// 			let bottom = document.documentElement.scrollHeight - (getPositionFromTopOfDoc(parent) + parent.getBoundingClientRect().height)
	// 			return bottom
	// 		}
	// 	}
	// })

	function getPositionFromTopOfDoc($el) {
		return $el.getBoundingClientRect().top + document.documentElement.scrollTop
	}

	// Before AFFIXING
	// anatomyAffix.on('affixed.bs.affix', () => {
	// 	console.log('affixing top')
	// 	productSlider.calculateProgress()
	// })
	// // After affixing at bottom
	// anatomyAffix.on('affixed-bottom.bs.affix affix-top.bs.affix', () => {
	// 	console.log('affixing bottom')
	// 	productSlider.stopCalculateProgress()
	// })
}

// locomotive.on("scroll", (args) => {
// 	if (typeof args.currentElements["anatomy"] === "object") {
// 		let progress = args.currentElements["anatomy"].progress;
// 		productSlider.updateProgress(progress);
// 	}
// });
