if (document.getElementsByClassName('trade-specification').length > 0) {
   let filters = document.querySelector('.filters'),
      activeFilters = [],
      specDetails = document.querySelectorAll(`.trade-specification__details__single`),
      debug = false,
      resetBtn = filters.querySelector('button[data-category="*"]');

   filters.addEventListener('click', (e) => {
      let button = e.target
      debug && console.log(button)

      if (button.localName != 'button') return // Ensures the clicked target is a filter button

      let category = button.dataset.category

      if (category == '*') {
         // Clear all filters
         activeFilters = []
         debug && console.log('cateogry is *')
      } else {
         // logic to add/remove the filter
         if (!activeFilters.includes(category)) {
            button.classList.add('filter-active')
            activeFilters.push(category);
         } else if (activeFilters.length > 0) {
            button.classList.remove('filter-active')
            activeFilters.splice(activeFilters.indexOf(category), 1);
         }
      }

      if (activeFilters.length > 0) {
         // There are filters, so filter
         debug && console.log('has filters')

         // Hide all initially
         specDetails.forEach((detail) => detail.style.display = 'none')

         // Display each active filter
         activeFilters.forEach((filter) => {
            let el = document.querySelector(`.trade-specification__details__single[data-category="${filter}"]`)
            el.style.display = 'block'
         })

         resetBtn.classList.remove('no-filters')
         resetBtn.classList.add('has-filters')

      } else {
         // No active filters, display all data
         debug && console.log('no filters')

         filters.querySelectorAll('button').forEach((filter) => filter.classList.remove('filter-active'))
         specDetails.forEach((detail) => detail.style.display = 'block')

         resetBtn.classList.remove('has-filters')
         resetBtn.classList.add('no-filters')
      }
   })
}