function isValidPostcode(p) {
	var e = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
	var e =
		/^[A-Z]{1,2}[0-9RCHNQ][0-9A-Z]?\s?[0-9][ABD-HJLNP-UW-Z]{2}$|^[A-Z]{2}-?[0-9]{4}$/;
	return e.test(p);
}

class Locations {
	constructor(el) {
		this.locationsMap = document.querySelector(el)

		if (!this.locationsMap) return

		this.locationsContainer = document.querySelector(".locations")
		this.locationsList = this.locationsContainer.querySelector(".locations-list_inner")
		this.locations = this.locationsList.querySelectorAll(".locations-list_single")
		this.locationsMapView = this.locationsContainer.querySelector(".locations-mapview")
		this.locationsMapFilters = this.locationsContainer.querySelector(".mapfilters")
		this.filters = null
		if(this.locationsMapFilters) {
			this.filters = this.locationsMapFilters.querySelectorAll('button')
		}
		this.activeFilters = null
		this.locationsMap = this.locationsContainer.querySelector(".locations-map")
		this.map = null
		this.markers = []
		this.markerInfo = []
		this.iconBase = "https://maps.google.com/mapfiles/kml/shapes/"
		this.icon = {
			// path: google.maps.SymbolPath.CIRCLE,
			path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
			fillOpacity: 1,
			fillColor: "#fff",
			strokeOpacity: 1,
			strokeWeight: 1,
			strokeColor: "#333",
			scale: 12,
		}

		this.activeInfoWindow = null;
		this.focusZoom = 9;
		this.location = null;
		this.locationLat = null;
		this.locationLng = null;
		this.locationForm = document.querySelector(
			"#locationsPostcodeForm"
		);
		this.locationError =
			document.querySelector(".postcode-notice");
		this.getCurrentLocation = document.querySelector(
			".getCurrentLocation"
		);

		this.autocomplete_locate = null;

		this.loadingIndicator =
			this.locationsContainer.querySelector(".loading");

		let styles = [
			{ elementType: "geometry", stylers: [{ color: "#f5f5f5" }] },
			{ elementType: "labels.icon", stylers: [{ visibility: "off" }] },
			{
				elementType: "labels.text.fill",
				stylers: [{ color: "#616161" }],
			},
			{
				elementType: "labels.text.stroke",
				stylers: [{ color: "#f5f5f5" }],
			},
			{
				featureType: "administrative.land_parcel",
				elementType: "labels.text.fill",
				stylers: [{ color: "#bdbdbd" }],
			},
			{
				featureType: "poi",
				elementType: "geometry",
				stylers: [{ color: "#eeeeee" }],
			},
			{
				featureType: "poi",
				elementType: "labels.text.fill",
				stylers: [{ color: "#757575" }],
			},
			{
				featureType: "poi.park",
				elementType: "geometry",
				stylers: [{ color: "#e5e5e5" }],
			},
			{
				featureType: "poi.park",
				elementType: "labels.text.fill",
				stylers: [{ color: "#9e9e9e" }],
			},
			{
				featureType: "road",
				elementType: "geometry",
				stylers: [{ color: "#ffffff" }],
			},
			{
				featureType: "road.arterial",
				elementType: "labels.text.fill",
				stylers: [{ color: "#757575" }],
			},
			{
				featureType: "road.highway",
				elementType: "geometry",
				stylers: [{ color: "#dadada" }],
			},
			{
				featureType: "road.highway",
				elementType: "labels.text.fill",
				stylers: [{ color: "#616161" }],
			},
			{
				featureType: "road.local",
				elementType: "labels.text.fill",
				stylers: [{ color: "#9e9e9e" }],
			},
			{
				featureType: "transit.line",
				elementType: "geometry",
				stylers: [{ color: "#e5e5e5" }],
			},
			{
				featureType: "transit.station",
				elementType: "geometry",
				stylers: [{ color: "#eeeeee" }],
			},
			{
				featureType: "water",
				elementType: "geometry",
				stylers: [{ color: "#c9c9c9" }],
			},
			{
				featureType: "water",
				elementType: "labels.text.fill",
				stylers: [{ color: "#9e9e9e" }],
			},
		];
		this.args = {
			zoom: 6,
			center: new google.maps.LatLng(0, 0),
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			mapTypeControl: false,
			streetViewControl: false,
			fullscreenControl: false,
			styles: styles,
		};

		this.initMap();
		this.initAutocomplete();
		this.setupEvents();
	}

	filterClicked(filter) {
		filter.classList.toggle('active')
		filter.classList.toggle('inactive')

		this.updateActiveFilters()
		this.handleLocationForm()
	}

	updateActiveFilters() {
		this.activeFilters = [];
		this.filters.forEach( (filter) => {
			if(filter.classList.contains('active')) {
				this.activeFilters.push(filter.dataset.type)
			}
		})
	}

	initMap() {
		// Init maps
		this.map = new google.maps.Map(this.locationsMap, this.args);

		// Set UK as view
		const UK = new google.maps.LatLng(54.622583, -4.426507);
		this.map.setCenter(UK);

		if(this.filters) {
			this.filters.forEach( (filter) => {
				filter.addEventListener('click', (e) => {
					this.filterClicked(filter)
				}, { passive: false} )
			})
		}
	}

	initAutocomplete() {
		let locationField = this.locationForm.elements["postcode"];

		let options = {
			componentRestrictions: { country: ["uk", "ie"] },
			fields: ["geometry", "address_components"],
		};
		this.autocomplete_locate = new google.maps.places.Autocomplete(
			locationField,
			options
		);

		let t = this;
		google.maps.event.addListener(this.autocomplete_locate, 'place_changed', function () {
			t.handleLocationForm();
		});

	}

	setupEvents() {
		if (navigator.geolocation) {
			this.getCurrentLocation.addEventListener("click", () => {
				const loading = this.getCurrentLocation.querySelector(".loader");
				loading.classList.remove("hidden");

				// Remove previous errors
				this.locationError.innerHTML = '';
				this.locationError.classList.remove("hidden");

				navigator.geolocation.getCurrentPosition(
					(position) => {
						loading.classList.add("hidden");

						this.locationLat = position.coords.latitude;
						this.locationLng = position.coords.longitude;


						this.handleValidLocation();
					},
					(error) => {
						let errorMessage = '';

						switch (error.code) {
							case error.PERMISSION_DENIED:
								errorMessage = "Please enable geolocation or manually enter your address."
								break;
							case error.POSITION_UNAVAILABLE:
							case error.TIMEOUT:
								errorMessage = "We were unable to get your location. Please enter your address manually."
								break;
							case error.UNKNOWN_ERROR:
							default:
								errorMessage = "An unknown error occurred. Please enter your address manually."
								break;
						}

						loading.classList.add("hidden");

						this.locationError.textContent = errorMessage
						this.locationError.classList.remove("hidden");
					},
					{
						maximumAge: 10000,
						timeout: 12000,
						enableHighAccuracy: false,
					}
				);
			});
		} else {
			this.getCurrentLocation.classList.add("hidden");
		}

		// Handle postcode form submit
		this.locationForm.addEventListener("submit", (e) => {
			e.preventDefault();
			this.handleLocationForm();
		});
	}

	// Runs when postcode form is submitted
	handleLocationForm() {
		let location = this.locationForm.elements["postcode"].value;
		if (!location) return;

		let geocoder = new google.maps.Geocoder(),
			t = this;

		// Test postcode
		geocoder.geocode(
			{
				address: location,
			},
			function (results, status) {
				if (status === "OK") {
					t.location = location;
					t.locationLat = results[0].geometry.location.lat();
					t.locationLng = results[0].geometry.location.lng();

					t.handleValidLocation();
				} else {
					t.handleInvalidLocation();
				}
			}
		);
	}

	handleValidLocation() {
		if (this.locationError) {
			this.locationError.classList.add("hidden");
		}
		this.requestLocations();
	}

	handleInvalidLocation() {
		this.locationError.classList.remove("hidden");
	}

	requestLocations() {
		let companyType = this.locationForm.elements["company_type"].value,
			t = this;

		jQuery.ajax({
			type: "POST",
			url: "/AjaxGetLocations.php",
			data: {
				location: {
					postcode: this.location,
					lat: this.locationLat,
					lng: this.locationLng,
				},
				companyType: companyType,
				filters: this.activeFilters,
				action: "getLocations",
			},
			beforeSend: function () {
				t.removeMarkers();
				t.setLoading();
				t.showMap();
			},
			success: function (data) {
				jQuery(t.locationsList).empty();
				t.locationsList.insertAdjacentHTML("beforeend", data);

				t.setNotLoading();
				t.updateLocations();
				t.addMarkers();
			},
			error: function (err) {
				console.log("Error: ", err);
			},
		});
	}

	setLoading() {
		this.locationsMapView.classList.add("is-loading");
		if(this.locationsMapFilters) this.locationsMapFilters.classList.add("is-loading");
	}
	setNotLoading() {
		this.locationsMapView.classList.remove("is-loading");
		if(this.locationsMapFilters) this.locationsMapFilters.classList.remove("is-loading");
	}
	
	showMap() {
		this.locationsMapView.classList.remove("hidden");
		if(this.locationsMapFilters) this.locationsMapFilters.classList.remove("hidden");
		google.maps.event.trigger(this.map, "resize");
		scrollToHash('#locations')
	}

	hideMap() {
		this.locationsMapView.classList.add("hidden");
		if(this.locationsMapFilters) this.locationsMapFilters.classList.add("hidden");
	}

	updateLocations() {
		this.locations = this.locationsList.querySelectorAll(
			".locations-list_single"
		);
	}

	addMarkers() {
		let t = this;
		this.markers = [];

		function createMarker(location, i) {
			var latlng = new google.maps.LatLng(
				location.dataset.lat,
				location.dataset.lng
			);
			var marker = new google.maps.Marker({
				position: latlng,
				map: t.map,
				title: location.dataset.title,
				label: {
					color: "#FFF",
					fontSize: "12px",
					fontWeight: "600",
					text: (i + 1).toString(),
				},
			});

			t.markers.push(marker);

			if (location.querySelector(".tooltip")) {
				let tooltip = location.querySelector(".tooltip").outerHTML;

				var infowindow = new google.maps.InfoWindow({
					content: tooltip,
				});

				let array = [infowindow, marker];
				t.markerInfo.push(array);

				google.maps.event.addListener(marker, "click", function (e) {
					if (t.activeInfoWindow) {
						t.activeInfoWindow.close();
					}

					t.map.setCenter(e.latLng);
					t.map.setZoom(t.focusZoom);
					infowindow.open(t.map, marker);

					t.activeInfoWindow = infowindow;
				});
			}
		}

		this.locations.forEach((location, i) => {
			if (location.dataset.lat) {
				createMarker(location, i);
			}
		});

		this.centerMap()
	}

	removeMarkers() {
		this.markers.forEach((marker) => {
			marker.setMap(null);
		});
	}

	centerMap() {
		var bounds = new google.maps.LatLngBounds();
		this.markers.forEach(function (marker) {
			bounds.extend({
				lat: marker.position.lat(),
				lng: marker.position.lng(),
			});
		});

		if (this.markers.length == 1) {
			// Case: Single marker.
			this.map.setCenter(bounds.getCenter());
			this.map.setZoom(12)
		} else {
			// Case: Multiple markers.
			this.map.fitBounds(bounds);
		}
	}
}

const map = new Locations("#locationsMap");


/**
 * initMap
 *
 * Renders a Google Map onto the selected jQuery element
 *
 * @date    22/10/19
 * @since   5.8.6
 *
 * @param   jQuery $el The jQuery element.
 * @return  object The map instance.
 */
function initMap( $el ) {

    // Find marker elements within map.
    var $markers = $el.find('.marker');

    // Create gerenic map.
    var mapArgs = {
        zoom        : $el.data('zoom') || 16,
        mapTypeId   : google.maps.MapTypeId.ROADMAP
    };
    var map = new google.maps.Map( $el[0], mapArgs );

    // Add markers.
    map.markers = [];
    $markers.each(function(){
        initMarker( $(this), map );
    });

    // Center map based on markers.
    centerMap( map );

    // Return map instance.
    return map;
}

(function(jQuery) {

	/*
	*  new_map
	*
	*  This function will render a Google Map onto the selected jQuery element
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$el (jQuery element)
	*  @return	n/a
	*/
	
	function new_map( $el ) {
	
		// var
		var $markers = $el.find('.marker');
	
	
		// vars
		var args = {
			zoom		: 16,
			center		: new google.maps.LatLng(0, 0),
			mapTypeId	: google.maps.MapTypeId.ROADMAP
		};
	
	
		// create map
		var map = new google.maps.Map( $el[0], args);
	
	
		// add a markers reference
		map.markers = [];
	
	
		// add markers
		$markers.each(function(){
	
			add_marker( jQuery(this), map );
	
		});
	
	
		// center map
		center_map( map );
	
	
		// return
		return map;
	
	}
	
	/*
	*  add_marker
	*
	*  This function will add a marker to the selected Google Map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	$marker (jQuery element)
	*  @param	map (Google Map object)
	*  @return	n/a
	*/
	
	function add_marker( $marker, map ) {
	
		// var
		var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
	
		// create marker
		var marker = new google.maps.Marker({
			position	: latlng,
			map			: map,
			
		});
	
		// add to array
		map.markers.push( marker );
	
		// if marker contains HTML, add it to an infoWindow
		if( $marker.html() )
		{
			// create info window
			var infowindow = new google.maps.InfoWindow({
				content		: $marker.html()
			});
	
			// show info window when marker is clicked
			google.maps.event.addListener(marker, 'click', function() {
	
				infowindow.open( map, marker );
	
			});
		}
	
	}
	
	/*
	*  center_map
	*
	*  This function will center the map, showing all markers attached to this map
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	4.3.0
	*
	*  @param	map (Google Map object)
	*  @return	n/a
	*/
	
	function center_map( map ) {
	
		// vars
		var bounds = new google.maps.LatLngBounds();
	
		// loop through all markers and create bounds
		jQuery.each( map.markers, function( i, marker ){
	
			var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
	
			bounds.extend( latlng );
	
		});
	
		// only 1 marker?
		if( map.markers.length == 1 )
		{
			// set center of map
			map.setCenter( bounds.getCenter() );
			map.setZoom( 14 );
		}
		else
		{
			// fit to bounds
			map.fitBounds( bounds );
	
		}
	
	}
	
	/*
	*  document ready
	*
	*  This function will render each map when the document is ready (page has loaded)
	*
	*  @type	function
	*  @date	8/11/2013
	*  @since	5.0.0
	*
	*  @param	n/a
	*  @return	n/a
	*/
	// global var
	var map = null;
	jQuery(document).ready(function(){
		jQuery('.acf-map').each(function(){
			// create map
			map = new_map( jQuery(this) );
		});
	});
	})(jQuery);
	