if(vimeoPlayerEl = document.querySelector('#vimeoVideo')) {
    let vimeoVideos = document.querySelectorAll('.resources_videos-list_single'),
        vimeoPlayerTitle = document.querySelector('#vimeoVideoTitle'),
        vimeoPlayerDescription = document.querySelector('#vimeoVideoDescription')
        vimeoPlayer = null;        

    vimeoVideos.forEach((video) => {
        video.addEventListener('click', () => { loadVideo(video) })
    })

    jQuery( document ).ready(() => {
        loadVideo(vimeoVideos[0])
    });

    function loadVideo(video) {
        let id = video.dataset.id,
            title = video.dataset.title,
            desc = video.dataset.description;

        if(!vimeoPlayer) {
            vimeoPlayer = new Vimeo.Player('vimeoVideo', {
                id: vimeoVideos[0].dataset.id,
                byline: false,
                portrait: false,
                title: false,
                color: 'E62B4D'
            })
        } else {
            vimeoPlayer.loadVideo(id).catch(function(error) {
                console.log(error);            
            });
        }

        vimeoPlayerTitle.innerText = title;
        vimeoPlayerDescription.innerText = desc;
    }

    // -----
    // Vimeo Events
    // -----

    // vimeoPlayer.on('play', function() {
    //     console.log('Played the video');
    // });


    // player.getVideoTitle().then(function(title) {
    //     console.log('title:', title);
    //   });
}