jQuery('a.header-featherlight').featherlight({
	targetAttr: 'href',
    afterClose: () => {
        handlePlayVideo();
    }
});

function handlePlayVideo() {
    if(headerVideo = document.querySelector('.page_header--media_video')) {
        let iframe = headerVideo.querySelector('iframe'),
            player = new Vimeo.Player(iframe);

        player.play()
    }
    return
}


//     let playButton = document.querySelector('#playHeaderVideo'),
//         stopButton = document.querySelector('#stopHeaderVideo'),
//         header = document.querySelector('.page_header');

//     playButton.addEventListener('click', () => {
//         header.classList.toggle('video_open')
//         locomotive.stop();

//     })
//     stopButton.addEventListener('click', () => {
//         header.classList.toggle('video_open')
//         locomotive.start();
//     })
// };